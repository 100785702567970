export default {
  navContainer: {
    position: 'absolute',
    left: ['0', '55px'],
    top: ['5px', '10px', '10px'],
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '5px 10px 5px 30px',
    cursor: 'pointer',
    zIndex: 2,
  },
  icon: {
    width: ['20px', '40px'],
    height: ['15px', '25px'],
  },
  linktitle: {
    fontFamily: 'spoqaBold',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
}
