export default {
  container: {
    position: 'relative',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  paddingContainer: {
    paddingTop: ['68px', '190px'],
  },
  pageWrapper: (isLayoutFullWidth) => ({
    paddingTop: ['0', `${isLayoutFullWidth ? '0px' : '73px'}`],
    paddingBottom: '0px',
    paddingLeft: `${isLayoutFullWidth ? '0px' : '20px'}`,
    paddingRight: `${isLayoutFullWidth ? '0px' : '20px'}`,
    maxWidth: `${!isLayoutFullWidth ? '1102px' : '100%'}`,
    margin: '0 auto',
    width: '100%',
  }),
  navBarWrapper: {
    width: '100%',
    backgroundColor: 'tmBlueV3',
    height: ['68px', '190px'],
    display: 'flex',
    transition: 'all 0.4s ease-in-out',
    position: 'fixed',
    zIndex: '1010',
    top: '0',
    right: '0',
    left: '0',
  },
  hiddenNavBarWrapper: {
    height: ['48px', '88px'],
    'nav, button': {
      transition: 'all 0.4s ease-in-out',
      opacity: '0',
    },
    img: {
      transition: 'width 0.4s ease-in-out',
      width: ['45px', '85px'],
    },
  },
  menuContainer: (session) => ({
    maxWidth: ['100%', '1102px'],
    padding: ['0 19px', ''],
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '0 auto',
    img: {
      width: ['62px', '152px'],
      height: 'auto',
    },
    nav: {
      width: session ? ['50px', '70px'] : ['50px', '70px'],
      textAlign: 'center',
      'div:first-child': {
        position: 'relative',
        padding: '0px',
        top: 'unset',
        left: 'unset',
        right: 'auto',
        img: {
          width: ['30px', '45px'],
        },
      },
      'div:last-child': {
        paddingTop: '0',
      },
    },
  }),
  logo: {
    textAlign: 'center',
    cursor: 'pointer',
    width: '60px',
    height: '40px',
    pt: '5px',
  },
  imageContainer: (smallHeadersImage) => ({
    height: smallHeadersImage ? ['', '500px'] : ['100vw', '500px', '500px'],
    width: '100vw',
    position: 'relative',
    maxHeight: ['500px', '500px'],
    top: '-1px',
    overflow: 'hidden',
    aspectRatio: smallHeadersImage ? '360 / 200' : '1 / 1',
  }),
  headerImage: (smallHeadersImage) => ({
    width: '100vw',
    height: smallHeadersImage ? ['', '500px'] : ['', '500px'],
    maxHeight: ['500px', '500px'],
    objectFit: 'cover',
    aspectRatio: smallHeadersImage ? '360 / 200' : '1 / 1',
  }),
  logOutButton: {
    width: ['55px', '70px'],
    textAlign: 'right',
    fontFamily: 'spoqaBold',
    fontSize: ['14px', '18px'],
    cursor: 'pointer',
  },
}
