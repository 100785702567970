export default {
  dropDownContentList: {
    padding: '0px 0px 0px 50px',
    listStyleType: 'none',
    margin: '0',
    display: 'none',
    transition: 'all 0.3s linear',

    active: {
      display: 'block',
    },

    li: {
      padding: '11px 0 5px 0',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      color: 'tmWhite',
      fontFamily: 'heading',
      borderBottom: '2px solid white',

      '&:last-child': {
        borderBottom: 'none',
      },

      span: {
        display: 'flex',
        justifyContent: 'space-between',
      },

      svg: {
        width: '11px',
        height: '19px',
        path: {
          fill: 'tmWhite',
          stroke: 'tmWhite',
          transition: 'stroke 0.3s linear',
        },
      },

      ':hover': {
        color: 'sideNavRed',
        svg: { path: { fill: 'sideNavRed', stroke: 'sideNavRed' } },
      },
    },
  },
  match: {
    color: 'tmRed',
    svg: { path: { fill: 'sideNavRed', stroke: 'sideNavRed' } },
  },
}
