/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useRouter } from 'next/router'
import redirectToPage from '/lib/redirect'

import { jsx, Image, Link, Text } from 'theme-ui'
import Box from '/components/Commons/Box'
import styles from './styles'
import RichTextRender from '/components/Commons/RichTextRender'

const Footer = ({ cmsContent, locale }) => {
  const logo = '/static/aia-tth-logo-white.png'
  const router = useRouter()
  const { links, richText, copyrightText } = cmsContent

  return (
    <footer>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.container}>
          <RichTextRender htmlCode={richText} />
          {links &&
            links.map(({ link }, index) => (
              <React.Fragment key={index}>
                <Link
                  sx={styles.link}
                  // TODO: create common link component using next link
                  href={
                    link[0].blank ? link[0].link : `/${locale}${link[0].link}`
                  }
                  target={link[0].blank ? '_blank' : '_self'}
                >
                  {link[0].text}
                </Link>
              </React.Fragment>
            ))}
          <Box sx={styles.logoFooterContainer}>
            <Image
              src={logo}
              onClick={() => redirectToPage(router, locale, '/')}
            />
          </Box>
          <Text sx={styles.copyrightText}>{copyrightText}</Text>
        </Box>
      </Box>
    </footer>
  )
}

export default Footer
