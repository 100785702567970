export default {
  container: {
    backgroundColor: 'tmDarkBlue',
    position: 'fixed',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    zIndex: 5,
    paddingTop: '40px',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease-in',
    width: ['100%', '100%', '50%'],
  },
  wrapper: {
    px: ['30px', '100px'],
    width: '100%',
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    width: '100%',
    margin: '21px 0',
    button: {
      margin: 0,
    },
  },
  iconContainer: {
    display: 'flex',
    padding: '0px',
    cursor: 'pointer',
    background: 'none',
    mt: ['20px', '30px'],
    svg: {
      width: '100%',
      path: { fill: 'tmWhite', transition: 'stroke 0.3s linear' },
    },
    ':hover': {
      background: 'none',
      svg: { path: { fill: 'sideNavRed' } },
    },
    position: 'relative',
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    maxHeight: '59px',
    marginTop: '10px',
    img: {
      width: '62px!important',
    },
  },
  linksList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    listStyleType: 'none',
    padding: '0px',
    margin: '0',
    color: 'tmWhite',

    '.activeItem': {
      color: 'sideNavRed',
      span: {
        svg: {
          transform: 'rotate(90deg)',
          path: { fill: 'sideNavRed', stroke: 'sideNavRed' },
        },
      },
    },
  },
  bar: {
    width: '100%',
    textTransform: 'uppercase',
    color: 'tmWhite',
    padding: '10px 0px',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: ['22px', '1.5rem'],
    borderBottom: '2px solid white',
    transition: 'color 0.3s linear',

    span: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    'span:hover': {
      color: 'sideNavRed',
      svg: { path: { fill: 'sideNavRed', stroke: 'sideNavRed' } },
    },

    svg: {
      transition: 'all 0.5s ease-out',
      width: '11px',
      height: '19px',
      transform: 'rotate(0deg)',
      path: {
        fill: 'tmWhite',
        stroke: 'tmWhite',
        transition: 'stroke 0.4s linear',
      },
    },
  },
  active: {
    color: 'sideNavRed',
    svg: {
      width: '11px',
      height: '19px',
      path: { fill: 'sideNavRed', stroke: 'sideNavRed' },
    },
  },
  menuOpened: {
    transform: 'translateX(0)',
    transition: 'transform 0.5s ease-in',
  },
  overlay: {
    position: 'absolute !important',
    top: 0,
    width: '100%',
    height: ['59px', '10%', '12%'],
    opacity: [0.8, 0.6],
    background:
      'linear-gradient(180deg, rgba(1,16,38,1) 0%, rgba(0,28,66,1) 100%)',
    zIndex: 0,
  },
}
