export default {
  contentWrapper: {
    backgroundColor: '#333D47',
    width: '100%',
    marginTop: ['80px', '', '140px'],
    px: ['20px', '', '165px'],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: ['100%', '896px'],
    margin: '0 auto',
    padding: ['33px 0 10px 0', '37px 0 33px 0'],
    fontFamily: 'spoqaRegular',

    p: {
      margin: ['0px 0px 10px 0px', '0px 0px 10px 0px'],
      fontSize: ['14px', '16px'],
      color: 'tmWhite',
    },
  },
  link: {
    fontFamily: 'spoqaRegular',
    fontSize: ['14px', '16px'],
    display: 'block',
    textDecoration: 'none',
    color: 'tmWhite',
    margin: ['0px 0px 10px 0px', '0px 0px 10px 0px'],
    '&:hover': {
      textDecoration: 'underline',
      color: 'tmWhite',
    },
  },
  margin: {
    width: '100%',
    height: '5px',
  },
  logoFooterContainer: {
    alignSelf: 'end',
    img: {
      width: '124px',
      cursor: 'pointer',
    },
  },
  copyrightText: {
    color: '#D6D8DA',
    fontSize: '14px',
    marginTop: '20px',
    fontFamily: 'light',
  },
}
