/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, Box, Text } from 'theme-ui'
import styles from './styles'

import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RichTextRender = ({ htmlCode, tableClassName, tableId }) => {
  const options = {
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <Text as="p">
          <strong>{text}</strong>
        </Text>
      ),
      [MARKS.SUBSCRIPT]: (text) => <Text as="sub">{text}</Text>,
    },
    renderNode: {
      [BLOCKS.TABLE]: (_, children) => (
        <Box sx={tableClassName}>
          <table id={tableId}>
            <tbody>{children}</tbody>
          </table>
        </Box>
      ),
      [BLOCKS.TABLE_ROW]: (_, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_, children) => <td>{children}</td>,
    },
    [BLOCKS.PARAGRAPH]: (_, children) => {
      if (children[0][1] === '') return
      return (
        <Text sx={styles.richText} as="p">
          {children}
        </Text>
      )
    },
    [BLOCKS.HEADING_1](_, children) {
      return <Text as="h1">{children}</Text>
    },
    [BLOCKS.HEADING_2](_, children) {
      return <Text as="h2">{children}</Text>
    },
    [BLOCKS.HEADING_3](_, children) {
      return <Text as="h3">{children}</Text>
    },
    [BLOCKS.HEADING_4](_, children) {
      return <Text as="h4">{children}</Text>
    },
    [BLOCKS.HEADING_5](_, children) {
      return <Text as="h5">{children}</Text>
    },
    [BLOCKS.HEADING_56](_, children) {
      return <Text as="h6">{children}</Text>
    },
    [BLOCKS.UL_LIST](_, children) {
      return (
        <ul>
          <li>{children}</li>
        </ul>
      )
    },
  }

  return (
    <React.Fragment>
      {documentToReactComponents(htmlCode, options)}
    </React.Fragment>
  )
}

export default RichTextRender
