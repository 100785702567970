export default {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalWrapper: {
    display: 'block',
    position: 'fixed',
    left: '50%',
    top: ['50%', '60%'],
    transform: 'translate(-50%, -50%)',
    width: ['91%', '40%'],
    maxWidth: '100%',
    height: ['40%', '52%'],
    backgroundColor: 'transparent',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 4,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  modalCloseButton: {
    fontSize: '1.4rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    mx: '10px',
    my: '5px',
    zIndex: '1',
    color: 'white',
  },
  modalText: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}
