/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Button, jsx } from 'theme-ui'
import React from 'react'
import ReactDOM from 'react-dom'
import styles from './styles'

const Modal = React.forwardRef(
  ({ onClose, children, customModalStyles }, ref) => {
    const modal = (
      <React.Fragment ref={ref}>
        <Box sx={styles.modalOverlay} />
        <Box sx={{ ...styles.modalWrapper, ...customModalStyles }}>
          <Box sx={styles.modalHeader}>
            <Button sx={styles.modalCloseButton} onClick={onClose}>
              <span>&times;</span>
            </Button>
          </Box>
          <Box sx={styles.modalText}>{children}</Box>
        </Box>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {children !== null && children !== undefined
          ? ReactDOM.createPortal(modal, document.body)
          : null}
      </React.Fragment>
    )
  },
)

export default Modal
