/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { useRouter } from 'next/router'
import { useSession, signOut } from 'next-auth/react'
import { SMALL_HEADER_PAGES } from '/constants/pages'

import { Box, jsx, Image, Button } from 'theme-ui'
import redirectToPage from '/lib/redirect'
import utils from '/utils'
import useControlNavbar from '/hooks/useControlNavbar'

import { NavBar } from '/components/Korea/NavBar'
import Footer from '/components/Korea/Footer'
import styles from './styles'

const NewKoreaDefaultLayout = (Page) =>
  function Component(props) {
    const { sideMenu, layout, footer, isLayoutFullWidth, locale } =
      props?.pageProps || {}

    const router = useRouter()
    const logo = '/static/aia-tth-logo-white.png'
    const [show] = useControlNavbar()
    const { data: session, status } = useSession()
    const loading = status === 'loading'

    const isMobile = utils.isMobile()

    const pageProps = {
      ...props,
      isMobile,
    }

    const currentPagePath = router.pathname.substring(4)
    const smallHeadersImage = Object.values(SMALL_HEADER_PAGES).some(
      (page) => page === currentPagePath,
    )

    const handleButtonRender = () => {
      loading && (
        <Button m={0} p={0} sx={styles.logOutButton} variant="simple">
          {' '}
        </Button>
      )
      return (
        <Button
          m={0}
          p={0}
          sx={styles.logOutButton}
          variant="simple"
          onClick={() =>
            !session
              ? redirectToPage(router, locale, '/login')
              : signOut({ callbackUrl: `/${locale}` })
          }
        >
          {session ? sideMenu.logoutButton : sideMenu.loginButton}
        </Button>
      )
    }

    return (
      <main sx={styles.container}>
        <Box sx={styles.content}>
          <Box
            sx={
              isMobile || show
                ? styles.navBarWrapper
                : { ...styles.navBarWrapper, ...styles.hiddenNavBarWrapper }
            }
          >
            <Box sx={styles.menuContainer(!!session)}>
              <NavBar
                sx={styles.navBar}
                navItems={sideMenu}
                locale={locale}
                session={session}
              />
              <Image
                src={!sideMenu.logo ? logo : sideMenu?.logo}
                sx={styles.logo}
                onClick={() => redirectToPage(router, locale, '/')}
              />
              {handleButtonRender()}
            </Box>
          </Box>
          <Box sx={styles.paddingContainer}></Box>
          {layout?.backgroundImage && (
            <Box sx={styles.imageContainer(smallHeadersImage)}>
              <Image
                sx={styles.headerImage(smallHeadersImage)}
                {...props}
                src={layout?.backgroundImage}
              />
              <Box sx={styles.overlay} />
            </Box>
          )}
          <Box sx={styles.pageWrapper(isLayoutFullWidth)}>
            <Page {...pageProps} />
          </Box>
        </Box>
        {footer && <Footer cmsContent={footer} locale={locale} />}
        <div data-iframe-height="1"></div>
      </main>
    )
  }

export default NewKoreaDefaultLayout
