import { useState, useEffect } from 'react'

const useControlNavbar = () => {
  const [show, setShow] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      setShow(window.scrollY < lastScrollY)
      setLastScrollY(window.scrollY)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar)
    return () => window.removeEventListener('scroll', controlNavbar)
  }, [lastScrollY])

  return [show]
}

export default useControlNavbar
