/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react'
import { jsx, Box, Image } from 'theme-ui'
import ListMenu from '/components/Korea/ListMenu'
import styles from './styles'

export const NavBar = ({ navItems, navStyles, locale, session }) => {
  const [openList, setOpenList] = useState(false)
  const icon = '/static/icons/hamburger.svg'

  const onClickMenu = (e) => {
    e.stopPropagation()
    setOpenList(true)
  }

  return (
    <nav sx={navStyles}>
      <Box sx={styles.navContainer} onClick={onClickMenu}>
        <Image src={icon} sx={styles.icon} />
      </Box>
      <ListMenu
        locale={locale}
        onClose={() => setOpenList(!openList)}
        opened={openList}
        navItems={navItems?.menu?.links || navItems?.menu?.entries}
        cmsLogo={navItems?.logo}
        session={session}
      />
    </nav>
  )
}
