/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useRouter } from 'next/router'
import Utils from '/utils'
import tracking from '/lib/tracking'
import redirectToPage from '/lib/redirect'
import { TRACKING_ACTION, TRACKING_CATEGORY } from '/constants/siteTagging'

import { jsx, Box, Button, Image, Text } from 'theme-ui'
import DropdownList from './components/DropdownList'

import styles from './styles'
import Cross from './close_cross.svg'
import Arrow from './left_arrow.svg'

const ListMenu = ({
  onClose,
  opened = false,
  navItems,
  locale,
  cmsLogo,
  session,
}) => {
  const logo = '/static/aia-tth-logo-white.png'
  const router = useRouter()
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState('')
  const [prevSelectedId, setPrevSelectedId] = React.useState('')
  const { sendCSMEvent } = tracking()

  React.useEffect(() => {
    if (opened) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [opened])

  const matchRoute = (link) => {
    const isHomeRoute = router.route === '/[locale]'
    if (link === '/') {
      if (isHomeRoute) return styles.active
      return
    }
    const isCurrentPath = router.route.includes(link)
    if (isCurrentPath) return styles.active
  }

  const buildDropDown = ({ mainLink, dropdownContentLinks }) => ({
    text: mainLink.text,
    link: mainLink.link,
    ...(dropdownContentLinks && {
      dropdownContentLinks: dropdownContentLinks,
    }),
  })

  // Check if text is empty and if dropdown or not
  const navigationItems = navItems
    .filter(
      (item) =>
        (item.text !== '' && item.text !== undefined) || !!item.mainLink.text,
    )
    .map((element) => (!element.mainLink ? element : buildDropDown(element)))

  const handleOnClick = (event, link, dropdownContentLinks) => {
    if (dropdownContentLinks && dropdownContentLinks.length > 0) {
      const parentElementId =
        event.target.parentElement?.id ||
        event.target.parentElement.parentElement?.id
      const contentElementId =
        event.target.nextElementSibling?.id ||
        event.target.parentElement.parentElement?.id

      setSelectedId(parentElementId)
      parentElementId === contentElementId && setIsOpen(!isOpen)
    } else {
      redirectToPage(router, locale, link)
      onClose()
    }
  }

  React.useEffect(() => {
    setPrevSelectedId(selectedId)

    const activeElement = document.getElementById(selectedId)
    const prevActiveElement = document.getElementById(prevSelectedId)

    selectedId && activeElement.classList.add('activeItem')
    prevSelectedId && prevActiveElement.classList.remove('activeItem')
  }, [selectedId])

  return (
    <Box sx={{ ...styles.container, ...(opened && styles.menuOpened) }}>
      <Box sx={styles.overlay} />
      <Box sx={styles.wrapper}>
        <Box sx={styles.logoContainer}>
          <Image
            src={!cmsLogo ? logo : cmsLogo}
            onClick={() => redirectToPage(router, locale, '/')}
          />
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button onClick={onClose} sx={styles.iconContainer}>
            <Cross />
          </Button>
        </Box>
        <ul sx={styles.linksList}>
          {navigationItems.map(
            ({ text, link, dropdownContentLinks }, index) => (
              <li
                key={index}
                sx={
                  matchRoute(link)
                    ? { ...styles.bar, ...styles.active }
                    : styles.bar
                }
                onClick={(e) => {
                  handleOnClick(e, link, dropdownContentLinks)
                  const trackInfo = link && Utils.getTrackingInfo(link)
                  trackInfo &&
                    sendCSMEvent(
                      trackInfo.eventName,
                      TRACKING_ACTION.clicked_cta,
                      TRACKING_CATEGORY.menu_bar,
                      trackInfo.eventAttribute,
                      locale,
                      session?.user_number,
                    )
                }}
                id={`item-${index}`}
              >
                <Text>
                  {text} <Arrow id={`item-${index}`} />
                </Text>
                {dropdownContentLinks !== undefined &&
                  dropdownContentLinks.length > 0 && (
                    <DropdownList
                      contentLinks={dropdownContentLinks}
                      matchRoute={matchRoute}
                      isOpen={isOpen}
                      onClick={handleOnClick}
                      id={`item-${index}`}
                      selectedId={selectedId}
                      locale={locale}
                      event={sendCSMEvent}
                      session={session}
                    />
                  )}
              </li>
            ),
          )}
        </ul>
      </Box>
    </Box>
  )
}

export default ListMenu
